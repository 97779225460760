import React from "react";
import "./Navigation.css";
import { CATEGORIES } from "../../utils/constants.js";
import CurrentUserContext from "../../contexts/CurrentUserContext";

const Navigation = ({ selectedCategory, onSelectCategory }) => {
  const currentUser = React.useContext(CurrentUserContext);

  const handleCategoryClick = (category) => {
    if (typeof category === "string" && category) {
      localStorage.setItem("selectedCategory", category); // Сохраняем текущую категорию в localStorage
      onSelectCategory(category); // Обновляем категорию
    }
  };

  return (
    <div className="navigation">
      {CATEGORIES.map(
        (category) =>
          (!currentUser.subscription ||
            currentUser.subscription[category] !== false ||
            currentUser.role === "admin" ||
            category === "ae") && (
            <div
              key={category}
              className={`navigation__item ${selectedCategory === category ? "navigation__item--active" : ""}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category.toUpperCase()}
            </div>
          ),
      )}
    </div>
  );
};

export default Navigation;
