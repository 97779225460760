import useVisible from "../../hooks/useVisible.jsx";
import React, { useState, useEffect } from "react";

function AutoColorsScheme({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const ADD_CONTROL_LAYER = `${optionsPath}.addControlLayer`;
  const ADD_EXPRESSION = `${optionsPath}.addExpression`;
  const SKIP_SHAPE_COLOR = `${optionsPath}.skipShapeColor`;
  const CONTROL_LAYER_ID = `${optionsPath}.controlLayerID`;
  const CONTROL_LAYER_COMP_NAME = `${optionsPath}.controlLayerCompName`;
  const SEPARATE_LAYERS_COLORS = `${optionsPath}.separateLayersColors`;

  const [controlLayer, setControlLayer] = useCepStorage(ADD_CONTROL_LAYER);
  const [expression, setExpression] = useCepStorage(ADD_EXPRESSION);
  const [skipShape, setSkipShape] = useCepStorage(SKIP_SHAPE_COLOR);
  const [layerID, setLayerID] = useCepStorage(CONTROL_LAYER_ID);
  const [compName, setCompName] = useCepStorage(CONTROL_LAYER_COMP_NAME);
  const [separateForLayers, setSeparateForLayers] = useCepStorage(
    SEPARATE_LAYERS_COLORS
  );

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
      if (result && result !== "undefined" && script === "$.ae.layerID") {
        result = result === "false" ? (result = false) : JSON.parse(result);
        setLayerID(result.id);
        setCompName(result.name);
      }
    });
  };

  useEffect(() => {
    if (layerID) {
      setControlLayer(false);
    }
  }, [layerID]);

  useEffect(() => {
    if (controlLayer) {
      setLayerID(false);
    }
  }, [controlLayer]);

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-palette"></i>
        </div>
        <div className="button-card__title">Auto Color Scheme</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className={`render-group__checkbox ${layerID ? "hidden" : ""}`}>
            <input
              type="checkbox"
              id="autoColorsSchemeControlLayer"
              checked={controlLayer}
              onChange={() => setControlLayer(!controlLayer)}
              className="hidden-checkbox"
            />
            <label htmlFor="autoColorsSchemeControlLayer">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Control Layer</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="autoColorsSchemeExpression"
              checked={expression}
              onChange={() => setExpression(!expression)}
              className="hidden-checkbox"
            />
            <label htmlFor="autoColorsSchemeExpression">
              <span className="custom-checkbox"></span>
              <span className="label-text">Add Expression</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="auto-colors-scheme-separate-layers"
              checked={separateForLayers}
              onChange={() => setSeparateForLayers(!separateForLayers)}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-colors-scheme-separate-layers">
              <span className="custom-checkbox"></span>
              <span className="label-text">Separate for Layers</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="autoColorsSchemeSkipShape"
              checked={skipShape}
              onChange={() => setSkipShape(!skipShape)}
              className="hidden-checkbox"
            />
            <label htmlFor="autoColorsSchemeSkipShape">
              <span className="custom-checkbox"></span>
              <span className="label-text">Skip Shape</span>
            </label>
          </div>
        </div>

        <div className={`set-button ${controlLayer ? "hidden" : ""}`}>
          <div
            onClick={() => handleClick("$.ae.layerID", false)}
            className={`render-group__button ${
              layerID ? "set-button-active exp" : ""
            }`}
          >
            {layerID ? (
              <div>{compName}</div>
            ) : (
              <>
                <i className="fa-regular fa-circle-a"></i>
                <span className="render-group__button-text">
                  Set Control Layer
                </span>
              </>
            )}
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({
                  type: "layer",
                  controlLayer: controlLayer,
                  expression: expression,
                  skipShape: skipShape,
                  notification: true,
                  layerID: layerID,
                  separateForLayers: separateForLayers,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span>Selected Layers</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({
                  type: "comp",
                  controlLayer: controlLayer,
                  expression: expression,
                  skipShape: skipShape,
                  notification: true,
                  layerID: layerID,
                  separateForLayers: separateForLayers,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-image-polaroid"></i>
            <span>Active Comp</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({
                  type: "selected comp",
                  controlLayer: controlLayer,
                  expression: expression,
                  skipShape: skipShape,
                  notification: true,
                  layerID: layerID,
                  separateForLayers: separateForLayers,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-solid fa-images"></i>
            <span>Selected Comp</span>
          </div>

          <div
            onClick={() =>
              handleClick(
                "autoColorsScheme",
                JSON.stringify({
                  type: "project",
                  controlLayer: controlLayer,
                  expression: expression,
                  skipShape: skipShape,
                  notification: true,
                  layerID: layerID,
                })
              )
            }
            className={`${
              separateForLayers ? "hidden" : "resolution-tools__button"
            }`}
          >
            <i class="fa-solid fa-images"></i>
            <span>Project</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutoColorsScheme;
