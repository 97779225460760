import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import copyClipboard from "../../utils/copyClipboard";
import "./ButtonCard.css";

import closeComps from "../../utils/buttons/closeComps";
import kanyeWest from "../../utils/buttons/kanyeWest";

const ButtonCard = ({
  useVisible,
  saveCepStorage,
  getCepStorage,
  useCepStorage,
  button,
  selectedTag,
  tags,
  ComponentToRender,
}) => {
  const currentUser = useContext(CurrentUserContext);
  const STORAGE_PATH = `buttons.${button._id}`;
  const ENABLED_PATH = `${STORAGE_PATH}.enabled`;
  const OPTIONS_PATH = `${STORAGE_PATH}.options`;
  const TAGS_PATH = `${STORAGE_PATH}.tags`;

  const [isVisible, setIsVisible] = useCepStorage(ENABLED_PATH);
  const { category, name, script, argument, icon } = button;

  const [buttonTags, setButtonTags] = useState(() => {
    const savedTags = getCepStorage(TAGS_PATH);
    return savedTags || [];
  });

  const buttonTagsSet = useMemo(() => new Set(buttonTags), [buttonTags]);

  const toggleButtonTag = useCallback((id, event) => {
    event.preventDefault();
    event.stopPropagation();

    setButtonTags((prevTags) => {
      const newTags = new Set(prevTags);
      if (newTags.has(id)) {
        newTags.delete(id);
      } else {
        newTags.add(id);
      }
      return Array.from(newTags);
    });
  }, []);

  useEffect(() => {
    saveCepStorage(TAGS_PATH, buttonTags);
  }, [buttonTags, TAGS_PATH, saveCepStorage]);

  function handleClick() {
    if (script !== "") {
      window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
      window.jsx.evalScript(
        `run(${script}(${JSON.stringify(argument)}));`,
        function (result) {
          if (script === "controlsDR" && result) {
            copyClipboard(result);
          }
        }
      );
    }

    if (name.toLowerCase().match("close compositions")) {
      closeComps();
    }

    if (name.toLowerCase().match("kanye west")) {
      kanyeWest();
    }
  }

  // Возвращаем функцию generateButtonTags для совместимости
  const generateButtonTags = useCallback(() => {
    return (
      <div className="button-card__tags" onClick={(e) => e.stopPropagation()}>
        {tags.map((tag) => (
          <div
            key={tag.id}
            className={`button-tag tags-container__tag ${
              buttonTagsSet.has(tag.id) ? "active" : ""
            }`}
            onClick={(event) => toggleButtonTag(tag.id, event)}
            style={{
              backgroundColor: tag.color,
              cursor: "pointer",
              position: "relative",
              zIndex: 2,
            }}
            title={tag.name}
          />
        ))}
      </div>
    );
  }, [tags, buttonTagsSet, toggleButtonTag]);

  if (ComponentToRender) {
    return (
      <li className="button-group">
        <ComponentToRender
          useVisible={useVisible}
          saveCepStorage={saveCepStorage}
          getCepStorage={getCepStorage}
          useCepStorage={useCepStorage}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          optionsPath={OPTIONS_PATH}
          selectedTag={selectedTag}
          buttonTags={buttonTags}
          generateButtonTags={generateButtonTags} // Передаем с оригинальным названием
          category={category}
        />
      </li>
    );
  }

  const isButtonDisabled =
    currentUser.subscription && currentUser.subscription[category] === false;

  if (selectedTag && !buttonTagsSet.has(selectedTag)) {
    return null;
  }

  return (
    <li
      className={`button-card ${
        isButtonDisabled ? "button-card--disabled" : ""
      }`}
      onClick={isButtonDisabled ? null : handleClick}
    >
      <div className="button-card__icon">
        <div dangerouslySetInnerHTML={{ __html: icon }} />
      </div>
      <div className="button-card__title">{name}</div>
      {generateButtonTags()}
    </li>
  );
};

export default React.memo(ButtonCard);
