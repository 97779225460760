import "./ShapeMatte.css";
import React, { useState, useEffect } from "react";
import { hexToRgbArray, rgbArrayToHex } from "../../utils/colors.js";
import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function ShapeMatte({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ShapeMatteGroupVisibility");
  const [stroke, setStroke] = useVisible("ShapeMatteStroke");
  const [strokeWidth, setStrokeWidth] = useState(
    localStorage.getItem("ShapeMatteStrokeWidth") || ""
  );
  const [responsive, setResponsive] = useVisible("ShapeMatteResponsive");
  const [padding, setPadding] = useState(
    localStorage.getItem("ShapeMattePadding") || ""
  );
  const [round, setRound] = useState(
    localStorage.getItem("ShapeMatteRound") || ""
  );

  const [color, setColor] = useState(
    localStorage.getItem("ShapeMatteColor") || "#ffffff"
  );

  const [shapeForm, setShapeForm] = useState(() => {
    return localStorage.getItem("ShapeMatteShape") || "rectangle";
  });

  // Обновляем local storage при изменении состояния
  useEffect(() => {
    localStorage.setItem("ShapeMatteShape", shapeForm);
  }, [shapeForm]);

  // Функция для обработки изменения значения в выпадающем списке
  const handleChange = (event) => {
    setShapeForm(event.target.value);
  };

  useEffect(() => {
    localStorage.setItem("ShapeMattePadding", padding);
  }, [padding]);

  useEffect(() => {
    localStorage.setItem("ShapeMatteRound", round);
  }, [round]);

  useEffect(() => {
    localStorage.setItem("ShapeMatteStrokeWidth", strokeWidth);
  }, [strokeWidth]);

  useEffect(() => {
    localStorage.setItem("ShapeMatteColor", color);
  }, [color]);

  const [shapeLabel, setShapeLabel] = useState(() => {
    return localStorage.getItem("ShapeMatteLabel") || "blue";
  });

  const labels = {
    none: {
      color: "666666",
      value: 0,
    },
    red: {
      color: "b53838",
      value: 1,
    },
    yellow: {
      color: "e4d84c",
      value: 2,
    },
    aqua: {
      color: "a9cbc7",
      value: 3,
    },
    pink: {
      color: "e5bcc9",
      value: 4,
    },
    lavender: {
      color: "a9a9ca",
      value: 5,
    },
    peach: {
      color: "e7c19e",
      value: 6,
    },
    "sea Foam": {
      color: "b3c7b3",
      value: 7,
    },
    blue: {
      color: "677de0",
      value: 8,
    },
    green: {
      color: "4aa44c",
      value: 9,
    },
    purple: {
      color: "8e2c9a",
      value: 10,
    },
    orange: {
      color: "e8920d",
      value: 11,
    },
    brown: {
      color: "7f452a",
      value: 12,
    },
    fuchsia: {
      color: "f46dd6",
      value: 13,
    },
    cyan: {
      color: "3da2a5",
      value: 14,
    },
    "sand stone": {
      color: "a89677",
      value: 15,
    },
    "dark Green": {
      color: "1e401e",
      value: 16,
    },
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const Dropdown = ({ id, name, labelText, selectedValue, onChange }) => {
    return (
      <div className="render-group__dropdown">
        <span className="graphics__dropdown-text">
          <span
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              backgroundColor: `#${labels[selectedValue]?.color}`,
              marginRight: "8px",
            }}
          ></span>
          {labelText}
        </span>
        <select id={id} name={name} value={selectedValue} onChange={onChange}>
          {Object.keys(labels).map((key) => (
            <option key={key} value={key}>
              {capitalizeFirstLetter(key)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleColorPicker = () => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(
      "run($.ae.colorPicker(" + JSON.stringify(hexToRgbArray(color)) + "));",
      function (data) {
        if (data) {
          data = data.split(",");
          setColor(rgbArrayToHex(data));
        }
      }
    );
  };

  const handleLabelChange = (event) => {
    setShapeLabel(event.target.value);
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-sharp fa-regular fa-message-text"></i>
        </div>
        <div className="button-card__title">Shape Matte</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <select value={shapeForm} onChange={handleChange}>
              <option value="rectangle">Rectangle</option>
              <option value="ellipse">Ellipse</option>
            </select>
          </div>
          <Dropdown
            id="mediaType"
            name="mediaType"
            labelText="Label Shape"
            selectedValue={shapeLabel}
            onChange={handleLabelChange}
          />
          <input
            className="rename__input shape__input"
            type="number"
            value={padding}
            onChange={(e) => setPadding(e.target.value)}
            placeholder="Padding"
          />
          <input
            className={`rename__input shape__input ${
              shapeForm !== "rectangle" ? "hidden" : ""
            }`}
            type="number"
            value={round}
            onChange={(e) => setRound(e.target.value)}
            placeholder="Round"
          />
          <div
            style={{
              backgroundColor: color,
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => handleColorPicker()}
          ></div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="responsive"
              checked={responsive}
              onChange={() => {
                setResponsive(!responsive);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="responsive">
              <span className="custom-checkbox"></span>
              <span className="label-text">Responsive</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="stroke"
              checked={stroke}
              onChange={() => {
                setStroke(!stroke);
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="stroke">
              <span className="custom-checkbox"></span>
              <span className="label-text">Stroke</span>
            </label>
          </div>

          {stroke && (
            <input
              className="rename__input shape__input"
              type="number"
              value={strokeWidth}
              onChange={(e) => setStrokeWidth(e.target.value)}
              placeholder="Width"
            />
          )}
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("responsiveRectangle", {
                type: "mask",
                responsive: responsive,
                stroke: false,
                padding: padding || 0,
                round: round || 0,
                color: [1, 1, 1],
                shape: shapeForm,
                label: labels[shapeLabel].value,
              })
            }
            className="render-group__button"
          >
            <i class="fa-light fa-circle-stop"></i>
            <span className="render-group__button-text">Mask</span>
          </div>
          <div
            onClick={() =>
              handleClick("responsiveRectangle", {
                type: "background",
                responsive: responsive,
                stroke: stroke,
                strokeWidth: strokeWidth || 5,
                padding: padding || 0,
                round: round || 0,
                color: hexToRgbArray(color),
                shape: shapeForm,
                label: labels[shapeLabel].value,
              })
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-rectangle-wide"></i>
            <span className="render-group__button-text">Background</span>
          </div>
          <div
            onClick={() => handleClick("connectShape")}
            className={`render-group__button ${!responsive ? "hidden" : ""}`}
          >
            <i class="fa-regular fa-link"></i>
            <span className="render-group__button-text">Connect Shape</span>
          </div>
          <div
            onClick={() =>
              handleClick("responsiveRectangle", {
                type: "word",
                responsive: responsive,
                stroke: stroke,
                strokeWidth: strokeWidth || 5,
                padding: padding || 0,
                round: round || 0,
                color: hexToRgbArray(color),
                shape: shapeForm,
                label: labels[shapeLabel].value,
              })
            }
            className={`render-group__button ${!responsive ? "hidden" : ""}`}
          >
            <i class="fa-light fa-square-w"></i>
            <span className="render-group__button-text">Word</span>
          </div>
          <div
            onClick={() =>
              handleClick("responsiveRectangle", {
                type: "lines",
                responsive: responsive,
                stroke: stroke,
                strokeWidth: strokeWidth || 5,
                padding: padding || 0,
                round: round || 0,
                color: hexToRgbArray(color),
                shape: shapeForm,
                label: labels[shapeLabel].value,
              })
            }
            className={`render-group__button ${!responsive ? "hidden" : ""}`}
          >
            <i class="fa-regular fa-grip-lines"></i>
            <span className="render-group__button-text">Lines</span>
          </div>
          <div
            onClick={() => handleClick("responsiveShapeUpdateExpression")}
            className={`render-group__button ${!responsive ? "hidden" : ""}`}
          >
            <i class="fa-regular fa-arrows-rotate"></i>
            <span className="render-group__button-text">Update Expression</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShapeMatte;
