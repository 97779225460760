import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";

function AutoMogrt({
  isVisible,
  setIsVisible,
  useCepStorage,
  saveCepStorage,
  getCepStorage,
  optionsPath,
  selectedTag,
  buttonTags,
  generateButtonTags,
}) {
  const ADD_GLOBAL_CONTROLS = `${optionsPath}.addGlobalControls`;
  const USE_COMP_NAME = `${optionsPath}.useCompName`;
  const TEXT_PLACEHOLDER_COLLAPSE = `${optionsPath}.textPlaceholderCollapse`;
  const REVERSE_CONTROLS = `${optionsPath}.reverseControls`;
  const SKIP_PROTECTED_REGION = `${optionsPath}.slipProtectedRegion`;

  const [global, setGlobal] = useCepStorage(ADD_GLOBAL_CONTROLS);
  const [mogrtName, setMogrtName] = useCepStorage(USE_COMP_NAME);
  const [collapse, setCollapse] = useVisible(TEXT_PLACEHOLDER_COLLAPSE);
  const [reverseControls, setReverseControls] = useCepStorage(REVERSE_CONTROLS);
  const [skipProtected, setSkipProtected] = useCepStorage(
    SKIP_PROTECTED_REGION
  );

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-circle-a"></i>
        </div>
        <div className="button-card__title">Auto Mogrt</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="auto-mogrt-global"
              checked={global}
              onChange={() => setGlobal(!global)}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-mogrt-global">
              <span className="custom-checkbox"></span>
              <span className="label-text">Global Control</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="reverse-control-mogrt"
              checked={reverseControls}
              onChange={() => setReverseControls(!reverseControls)}
              className="hidden-checkbox"
            />
            <label htmlFor="reverse-control-mogrt">
              <span className="custom-checkbox"></span>
              <span className="label-text">Reverse Controls</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="mogrt-name"
              checked={mogrtName}
              onChange={() => setMogrtName(!mogrtName)}
              className="hidden-checkbox"
            />
            <label htmlFor="mogrt-name">
              <span className="custom-checkbox"></span>
              <span className="label-text">Comp Name</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="auto-mogrt-collapse"
              checked={collapse}
              onChange={() => setCollapse(!collapse)}
              className="hidden-checkbox"
            />
            <label htmlFor="auto-mogrt-collapse">
              <span className="custom-checkbox"></span>
              <span className="label-text">Collapse Text Comp</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="skip-protected"
              checked={skipProtected}
              onChange={() => setSkipProtected(!skipProtected)}
              className="hidden-checkbox"
            />
            <label htmlFor="skip-protected">
              <span className="custom-checkbox"></span>
              <span className="label-text">Skip Protected</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick("autoMogrt", {
                global: global,
                reverseControls: reverseControls,
                name: mogrtName,
                collapse: collapse,
                skipProtected: skipProtected,
                adobeStock: false,
              })
            }
            className="render-group__button"
          >
            <i class="fa-light fa-square-plus"></i>
            <span className="render-group__button-text">Create</span>
          </div>
          <div
            onClick={() =>
              handleClick("autoMogrt", {
                global: global,
                reverseControls: reverseControls,
                name: mogrtName,
                collapse: collapse,
                skipProtected: skipProtected,
                adobeStock: true,
              })
            }
            className="render-group__button"
          >
            <i class="fa-light fa-square-a"></i>
            <span className="render-group__button-text">Create Adobe</span>
          </div>
          <div
            onClick={() =>
              handleClick("autoMogrt", {
                global: global,
                reverseControls: reverseControls,
                name: mogrtName,
                collapse: collapse,
                skipProtected: skipProtected,
                adobeStock: false,
                standartPanel: true,
              })
            }
            className="render-group__button"
          >
            <i class="fa-light fa-square-s"></i>
            <span className="render-group__button-text">Create Standart</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutoMogrt;
