import useVisible from "../../hooks/useVisible.jsx";
import "./RenderLayers.css";

function RenderLayers({ selectedTag, buttonTags, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("RenderGroupVisibility");
  const [changeName, setChangeName] = useVisible("changeName");
  const [originalColor, setOriginalColor] = useVisible(
    "RenderGroupOriginalColor"
  );
  const [saveFill, setSaveFill] = useVisible("RenderGroupSaveFill");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && !buttonTags.includes(selectedTag)) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i className="fa-sharp fa-regular fa-file-export"></i>
        </div>
        <div className="button-card__title">Render Layers</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="changeName"
              checked={changeName}
              onChange={() => setChangeName(!changeName)}
              className="hidden-checkbox"
            />
            <label htmlFor="changeName">
              <span className="custom-checkbox"></span>
              <span className="label-text">Change Name</span>
            </label>
          </div>
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "ppRenderLayer",
                changeName ? "true, true" : "true, false"
              )
            }
            className="render-group__button"
          >
            <i className="fa-regular fa-layer-plus"></i>
            <span className="render-group__button-text">Layer</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "ppRenderLayer",
                changeName ? "false, true" : "false, false"
              )
            }
            className="render-group__button"
          >
            <i className="fa-regular fa-layer-group"></i>
            <span className="render-group__button-text">Layer Group</span>
          </div>
        </div>

        <div className="render-group__checkbox-group mg-top">
          <input
            type="checkbox"
            id="originalColor"
            checked={originalColor}
            onChange={() => setOriginalColor(!originalColor)}
            className="hidden-checkbox"
          />
          <label htmlFor="originalColor">
            <span className="custom-checkbox"></span>
            <span className="label-text">Original Color</span>
          </label>
          <input
            type="checkbox"
            id="saveFill"
            checked={saveFill}
            onChange={() => setSaveFill(!saveFill)}
            className="hidden-checkbox"
          />
          <label htmlFor="saveFill">
            <span className="custom-checkbox"></span>
            <span className="label-text">Save Fill</span>
          </label>
        </div>
        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "ppLayerToFootage",
                JSON.stringify({
                  originalColor: originalColor,
                  type: "layer",
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span className="render-group__button-text">Render Layers</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "ppLayerToFootage",
                JSON.stringify({
                  originalColor: originalColor,
                  type: "selected comp",
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-images"></i>
            <span className="render-group__button-text">Render Items</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "layerToFootageImport",
                JSON.stringify({
                  saveFill: saveFill,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-solid fa-rotate"></i>
            <span className="render-group__button-text">Replace Layers</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default RenderLayers;
